import React from "react";
import styled from "styled-components";
import GoogleMapReact from "google-map-react";

import { device } from "../../utils";
import iconPin from "../../assets/image/map-marker.svg";

const MapStyled = styled.div`
  width: 100%;
  height: 500px;
  margin-bottom: 50px;

  .pin {
    display: flex;
    align-items: center;
    width: 180px;
    color: var(--main-blue);
  }

  @media ${device.lg} {
    margin-top: 0px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 47%;
  }
`;

const LocationPin = () => (
  <div className="pin">
    <img
      src={iconPin}
      className="pin-icon"
      alt=""
      style={{ position: "absolute", transform: "translate(-50%, -50%)" }}
    />
  </div>
);

const MapGoogle = (props) => {
  const location = {
    lat: 51.916624201566286,
    lng: 4.509718120083248,
  };

  return (
    <>
      <MapStyled>
        <GoogleMapReact
          bootstrapURLKeys={{ key: `AIzaSyDpqIP2yOZBWjAcknp1szptkyh0fk6zGQI` }}
          defaultCenter={location}
          defaultZoom={13}
          className="h-100 w-100"
          // key={location}
        >
          <LocationPin
            lat={props.officeAddress ? 51.917982503505 : 51.913171439048526}
            lng={props.officeAddress ? 4.531661059844362 : 4.492869922239487}
          />
        </GoogleMapReact>
      </MapStyled>
    </>
  );
};

export default MapGoogle;
